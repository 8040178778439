<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>App Version Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <TabView>
                    <TabPanel header="Android App">
                        <div class="card">
                            <div class="p-pt-2 p-px-3">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="clientname"> Android App URL <span class="p-invalid p-error">*</span>
                                        </label>
                                        <div v-if="androidValueLinkExist" style="line-height: 38px;">
                                            <a class="view-btn" target="_blank" :href="this.androidurl.androidurlvalue" v-tooltip.top="'Click to Open'">{{
                        this.androidurl.androidurlvalue }}</a>
                                            <Button icon="pi pi-pencil" v-tooltip.right="'Edit App URL'" class="p-button-rounded p-ml-3"
                                                @click="editAndroidLinkTonggle()" />
                                        </div>
                                        <div v-if="!androidValueLinkExist">
                                            <div class="p-d-flex p-ai-top p-jc-between">
                                                <div style="width: 100%;">
                                                    <InputText id="androidurlvalue" placeholder="Enter Android App URL"
                                                        v-model.trim="androidurl.androidurlvalue" required="true" maxlength="250" autofocus
                                                        :class="{ 'p-invalid': androidlinksubmitted && !androidurl.androidurlvalue }" />
                                                    <div style="height: 14px;">
                                                        <small class="p-invalid p-error" v-if="v$.androidurl.androidurlvalue.$error">{{
                        v$.androidurl.androidurlvalue.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button v-if="!androidLinkLoader" icon="pi pi-check" v-tooltip.top="'Submit'"
                                                        class="p-button-rounded p-ml-3" @click="addEditAndroidLinkBtn()"></Button>
                                                    <Button v-show="androidLinkLoader" icon="pi pi-spin pi-spinner" class="p-button-rounded p-ml-3"></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-12 p-md-3">
                                        <label for="clientname"> Android App Version <span class="p-invalid p-error">*</span>
                                        </label>
                                        <div v-if="androidValueVersionExist" style="line-height: 38px;">
                                            <label>{{ this.androidvr.androidversionvalue }}</label>
                                            <Button icon="pi pi-pencil" v-tooltip.right="'Edit App Version'" class="p-button-rounded p-ml-3"
                                                @click="editAndroidVersionTonggle()" />
                                        </div>
                                        <div v-if="!androidValueVersionExist">
                                            <div class="p-d-flex p-ai-top p-jc-between">
                                                <div style="width: 100%;">
                                                    <InputText id="androidversionvalue" placeholder="Enter App Version"
                                                        v-model.trim="androidvr.androidversionvalue" @keypress="onlyNumberwithDot" required="true"
                                                        maxlength="10" autofocus :class="{ 'p-invalid': androidsubmitted && !androidvr.androidversionvalue }" />
                                                    <div style="height: 14px;">
                                                        <small class="p-invalid p-error" v-if="v$.androidvr.androidversionvalue.$error">{{
                        v$.androidvr.androidversionvalue.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button v-if="!androidVersionLoader" icon="pi pi-check" v-tooltip.top="'Submit'"
                                                        class="p-button-rounded p-ml-3" @click="addEditAndroidVersionBtn()"></Button>
                                                    <Button v-show="androidVersionLoader" icon="pi pi-spin pi-spinner" class="p-button-rounded p-ml-3"></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Toast />
                        </div>
                    </TabPanel>
                    <TabPanel header="iOS App">
                        <div class="card">
                            <div class="p-pt-2 p-px-3">
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-12 p-md-12">
                                        <label for="clientname"> iOS App URL <span class="p-invalid p-error">*</span>
                                        </label>
                                        <div v-if="iosValueLinkExist" style="line-height: 38px;">
                                            <a class="view-btn" target="_blank" :href="this.iosurl.iosurlvalue" v-tooltip.top="'Click to Open'">{{
                        this.iosurl.iosurlvalue }}</a>
                                            <Button icon="pi pi-pencil" v-tooltip.right="'Edit App URL'" class="p-button-rounded p-ml-3"
                                                @click="editiOSLinkTonggle()" />
                                        </div>
                                        <div v-if="!iosValueLinkExist">
                                            <div class="p-d-flex p-ai-top p-jc-between">
                                                <div style="width: 100%;">
                                                    <InputText id="iosurlvalue" placeholder="Enter ios App URL" v-model.trim="iosurl.iosurlvalue"
                                                        required="true" maxlength="250" autofocus
                                                        :class="{ 'p-invalid': ioslinksubmitted && !iosurl.iosurlvalue }" />
                                                    <div style="height: 14px;">
                                                        <small class="p-invalid p-error" v-if="v$.iosurl.iosurlvalue.$error">{{
                        v$.iosurl.iosurlvalue.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button v-if="!iosLinkLoader" icon="pi pi-check" v-tooltip.top="'Submit'" class="p-button-rounded p-ml-3"
                                                        @click="addEditiOSLinkBtn()"></Button>
                                                    <Button v-show="iosLinkLoader" icon="pi pi-spin pi-spinner" class="p-button-rounded p-ml-3"></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-12 p-md-3">
                                        <label for="clientname"> iOS App Version <span class="p-invalid p-error">*</span>
                                        </label>
                                        <div v-if="iosValueVersionExist" style="line-height: 38px;">
                                            <label>{{ this.iosvr.iosversionvalue }}</label>
                                            <Button icon="pi pi-pencil" v-tooltip.right="'Edit App Version'" class="p-button-rounded p-ml-3"
                                                @click="editiOSVersionTonggle()" />
                                        </div>
                                        <div v-if="!iosValueVersionExist">
                                            <div class="p-d-flex p-ai-top p-jc-between">
                                                <div style="width: 100%;">
                                                    <InputText id="iosversionvalue" placeholder="Enter App Version" v-model.trim="iosvr.iosversionvalue"
                                                        @keypress="onlyNumberwithDot" required="true" maxlength="10" autofocus
                                                        :class="{ 'p-invalid': iossubmitted && !iosvr.iosversionvalue }" />
                                                    <div style="height: 14px;">
                                                        <small class="p-invalid p-error" v-if="v$.iosvr.iosversionvalue.$error">{{
                                                            v$.iosvr.iosversionvalue.$errors[0].$message }}</small>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button v-if="!iosVersionLoader" icon="pi pi-check" v-tooltip.top="'Submit'" class="p-button-rounded p-ml-3"
                                                        @click="addEditiOSVersionBtn()"></Button>
                                                    <Button v-show="iosVersionLoader" icon="pi pi-spin pi-spinner" class="p-button-rounded p-ml-3"></Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Toast />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers } from '@vuelidate/validators';
export default {
    data () {
        return {
            v$: useValidate(),
            routeParam: '',
            localClientName: '',
            // Android APP flag
            androidurl: {
                //androidurlvalue: 'https://play.google.com/store/apps/details?id=com.edynamics.lokneta',
                androidurlvalue: ''
            },
            androidvr: {
                //androidversionvalue: '4.0.1',
                androidversionvalue: ''
            },
            androidsubmitted: false,
            androidlinksubmitted: false,
            androidValueLinkExist: false,
            androidValueVersionExist: false,
            androidLinkLoader: false,
            androidVersionLoader: false,
            // iOS App flag
            iosurl: {
                //iosurlvalue: 'https://apps.apple.com/in/app/lok-neta/id6504969922',
                iosurlvalue: ''
            },
            iosvr: {
                //iosversionvalue: '4.0.2',
                iosversionvalue: ''
            },
            iossubmitted: false,
            ioslinksubmitted: false,
            iosValueLinkExist: false,
            iosValueVersionExist: false,
            iosLinkLoader: false,
            iosVersionLoader: false,
            androidVerExistId: '',
            iosVerExistId: ''
        };
    },
    validations () {
        return {
            androidvr: {
                androidversionvalue: { required: helpers.withMessage( 'Please enter android app version', required ) },
            },
            androidurl: {
                androidurlvalue: { required: helpers.withMessage( 'Please enter android app url', required ) },
            },
            iosvr: {
                iosversionvalue: { required: helpers.withMessage( 'Please enter iOS app version', required ) },
            },
            iosurl: {
                iosurlvalue: { required: helpers.withMessage( 'Please enter iOS app url', required ) },
            },
        };
    },
    ApiService: null,
    interval: null,
    created () {
        this.ApiService = new ApiService();
    },
    mounted () {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.getAppVersion()
    },
    methods: {
        goToDashboard () {
            router.push( {
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            } );
        },
        goToPrevious () {
            router.push( {
                name: "banner-images",
                params: { clientSubId: this.routeParam },

            } );
        },
        onlyNumberwithDot ( $event ) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ( keyCode < 46 || keyCode > 57 ) {
                $event.preventDefault();
            }
        },
        getAppVersion () {
            this.addClientFundStatus = true;
            this.ApiService.getClientAppVersion( { clientId: this.routeParam } ).then( ( data ) => {
                if ( data.status == 200 ) {
                    console.log( data.data );
                    for ( let i = 0; i < data.data.length; i++ ) {
                        if ( data.data[ i ][ 'ea2' ] == 1 ) {
                            this.androidVerExistId = data.data[ i ][ 'ea1' ]
                            if ( data.data[ i ][ 'ea6' ] != null && data.data[ i ][ 'ea6' ] != "" ) {
                                this.androidurl.androidurlvalue = data.data[ i ][ 'ea6' ]
                                this.androidValueLinkExist = true
                            }
                            if ( data.data[ i ][ 'ea3' ] != null && data.data[ i ][ 'ea3' ] != "" ) {
                                this.androidvr.androidversionvalue = data.data[ i ][ 'ea3' ]
                                this.androidValueVersionExist = true
                            }
                        } else if ( data.data[ i ][ 'ea2' ] == 2 ) {
                            this.iosVerExistId = data.data[ i ][ 'ea1' ]
                            if ( data.data[ i ][ 'ea6' ] != null && data.data[ i ][ 'ea6' ] != "" ) {
                                this.iosurl.iosurlvalue = data.data[ i ][ 'ea6' ]
                                this.iosValueLinkExist = true
                            }
                            if ( data.data[ i ][ 'ea3' ] != null && data.data[ i ][ 'ea3' ] != "" ) {
                                this.iosvr.iosversionvalue = data.data[ i ][ 'ea3' ]
                                this.iosValueVersionExist = true
                            }
                        }
                    }
                }
            } );
        },
        // Android App function start here
        addEditAndroidVersionBtn () {
            this.androidsubmitted = true;
            this.errormsg = '';
            let fields = {};
            if(this.androidVerExistId != null && this.androidVerExistId != ''){
                fields[ 'id' ] = this.androidVerExistId
            }
            fields[ 'clientId' ] = this.routeParam
            fields[ 'mobile_type' ] = 1;
            if ( this.androidvr.androidversionvalue != null && this.androidvr.androidversionvalue != "" ) {
                fields[ 'version_of_app' ] = this.androidvr.androidversionvalue;
            }
            this.v$.androidvr.$validate();
            if ( !this.v$.androidvr.$error ) {
                this.androidVersionLoader = true;
                this.ApiService.updateClientAppVersion(fields).then((items) => {
                    if (items.status == 200) {
                        this.androidVersionLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.androidsubmitted = false;
                        this.getAppVersion()
                    } else {
                        this.androidsubmitted = false;
                        this.androidVersionLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addEditAndroidLinkBtn () {
            this.androidlinksubmitted = true;
            this.errormsg = '';
            let fields = {};
            if(this.androidVerExistId != null && this.androidVerExistId != ''){
                fields[ 'id' ] = this.androidVerExistId
            }
            fields[ 'clientId' ] = this.routeParam
            fields[ 'mobile_type' ] = 1;
            if ( this.androidurl.androidurlvalue != null && this.androidurl.androidurlvalue != "" ) {
                fields[ 'app_url' ] = this.androidurl.androidurlvalue;
            }
            this.v$.androidurl.$validate();
            if ( !this.v$.androidurl.$error ) {
                this.androidLinkLoader = true;
                this.ApiService.updateClientAppVersion(fields).then((items) => {
                    if (items.status == 200) {
                        this.androidLinkLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.androidlinksubmitted = false;
                        this.getAppVersion()
                    } else {
                        this.androidlinksubmitted = false;
                        this.androidLinkLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        editAndroidLinkTonggle () {
            this.androidValueLinkExist = false;
        },
        editAndroidVersionTonggle () {
            this.androidValueVersionExist = false;
        },
        // iOS App function start here
        addEditiOSVersionBtn () {
            this.iossubmitted = true;
            this.errormsg = '';
            let fields = {};
            if(this.iosVerExistId != null && this.iosVerExistId != ''){
                fields[ 'id' ] = this.iosVerExistId
            }
            fields[ 'clientId' ] = this.routeParam
            fields[ 'mobile_type' ] = 2;
            if ( this.iosvr.iosversionvalue != null && this.iosvr.iosversionvalue != "" ) {
                fields[ 'version_of_app' ] = this.iosvr.iosversionvalue;
            }
            this.v$.iosvr.$validate();
            if ( !this.v$.iosvr.$error ) {
                this.iosVersionLoader = true;
                this.ApiService.updateClientAppVersion(fields).then((items) => {
                    if (items.status == 200) {
                        this.iosVersionLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.iossubmitted = false;
                        this.getAppVersion()
                    } else {
                        this.iossubmitted = false;
                        this.iosVersionLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        addEditiOSLinkBtn () {
            this.ioslinksubmitted = true;
            this.errormsg = '';
            let fields = {};
            if(this.iosVerExistId != null && this.iosVerExistId != ''){
                fields[ 'id' ] = this.iosVerExistId
            }
            fields[ 'clientId' ] = this.routeParam
            fields[ 'mobile_type' ] = 2;
            if ( this.iosurl.iosurlvalue != null && this.iosurl.iosurlvalue != "" ) {
                fields[ 'app_url' ] = this.iosurl.iosurlvalue;
            }
            this.v$.iosurl.$validate();
            if ( !this.v$.iosurl.$error ) {
                this.iosLinkLoader = true;
                this.ApiService.updateClientAppVersion(fields).then((items) => {
                    if (items.status == 200) {
                        this.iosLinkLoader = false;
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.ioslinksubmitted = false;
                        this.getAppVersion()
                    } else {
                        this.ioslinksubmitted = false;
                        this.iosLinkLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        editiOSLinkTonggle () {
            this.iosValueLinkExist = false;
        },
        editiOSVersionTonggle () {
            this.iosValueVersionExist = false;
        }
    },
};
</script>
<style scoped></style>